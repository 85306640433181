.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  max-width: 100%;
  height: auto;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  /* background: #39ac31; */
  background: #1e2466;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
  text-align: center;
}
.h2 {
  font-size: 40px;
}

p {
  font-size: 16px;
}

/* Media Queries for Tablet */
@media only screen and (max-width: 768px) {
  .four_zero_four_bg h1 {
    font-size: 60px;
  }
  .h2 {
    font-size: 30px;
  }
}

/* Media Queries for Mobile */
@media only screen and (max-width: 480px) {
  .four_zero_four_bg h1 {
    font-size: 60px;
  }
  .h2 {
    font-size: 30px;
  }
}
