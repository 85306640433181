@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400&display=swap');
body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif !important; */
  /* font-family: 'Noto Sans', sans-serif; */
font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* body::-webkit-scrollbar{
  background-color: transparent;
}

body::-webkit-scrollbar-thumb{
  background: linear-gradient(red,blue,green);
  border-radius: 20px;
} */