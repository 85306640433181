@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes roll {
  0% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  50% {
    transform: rotateX(40deg) rotateY(180deg) rotateZ(320deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}
@keyframes roll {
  0% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  50% {
    transform: rotateX(40deg) rotateY(180deg) rotateZ(320deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}
#loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  perspective: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  font-family: "Avenir Next", "Avenir Neue", "Avenir", Futura, "Century Gothic",
    "Helvetica Neue", Helvetica, Roboto, Arial, Sans-serif;
  font-weight: bold;
  text-align: center;
}
#loading p {
  margin: 80px auto 0;
  color: #023;
  letter-spacing: 0.5em;
  text-indent: 0.5em;
  font-size: 18px;
  -webkit-animation: blink 1s ease-in-out infinite alternate;
  animation: blink 1s ease-in-out infinite alternate;
}
#loading .dice {
  background: #000;
  width: 80px;
  height: 80px;
  position: relative;
  transform-style: preserve-3d;
  transition: 1s ease-out;
  -webkit-animation: roll 4s linear infinite;
  animation: roll 4s linear infinite;
}
#loading .dice div {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  background: #023;
  color: #b4b2b2;
  line-height: 80px;
  font-size: 40px;
}
#loading .dice .front {
  top: 0;
  left: 0;
  transform: translateZ(40px);
}
#loading .dice .back {
  top: 0;
  left: 0;
  transform: translateZ(-40px) rotateY(180deg);
}
#loading .dice .left {
  top: 0;
  left: 40px;
  transform: rotateY(90deg);
}
#loading .dice .right {
  top: 0;
  right: 40px;
  transform: rotateY(-90deg);
}
#loading .dice .top {
  top: 40px;
  left: 0;
  transform: rotateX(-90deg);
}
#loading .dice .bottom {
  bottom: 40px;
  left: 0;
  transform: rotateX(90deg);
}
